import { TimeZone } from "../../data/_gen/types"

export interface AppTimeZoneIds {
  // The effective time zone is the actual time zone that was used
  // to generate content.
  effective: string

  // The system time zone is the time zone the user's browser
  // is set to. It's obtained using a cookie. If the cookie is
  // not set, it's obtained from the IP address.
  system: string
  // The override time zone is the time zone the user has
  // selected to override the system time zone. It's obtained
  // using a cookie.
  override?: string
}

// This header is set by Vercel to the time zone of the IP address.
export const IP_TIMEZONE_HEADER = "x-vercel-ip-timezone"

// This is the time zone we use if we can't get the user's time zone.
export const LAST_RESORT_TIMEZONE: TimeZone = {
  city: "London",
  coordinates: {
    latitude: 513.05,
    longitude: -0.12527777777777777,
  },
  countryCodes: ["GB", "GG", "IM", "JE"],
  id: "Europe/London",
  region: "Europe",
}

"use client"

/**
 * This file defines top level providers for the app. These are needed to render
 * the global error screen.
 */

import { NextIntlClientProvider } from "next-intl"
import { ToastProvider } from "@daybridge/components"
import { AppTimeZoneIds } from "../../lib/timezones/timezone"
import { TimeZoneProvider } from "./(boundary)/_providers/TimeZoneProvider"
import { BaseURLProvider } from "./(boundary)/_providers/BaseURLProvider"

interface RootProvidersProps {
  children: React.ReactElement
  locale: string
  timeZones: AppTimeZoneIds
  baseURLOverride?: string
  now: Date
  localeMessages: Record<string, Record<string, string>>
}

export const RootProviders: React.FC<RootProvidersProps> = ({
  children,
  locale,
  baseURLOverride,
  timeZones,
  localeMessages,
  now,
}) => {
  if (!process.env.NEXT_PUBLIC_API_BASE_URL) {
    throw new Error("NEXT_PUBLIC_API_BASE_URL is not defined")
  }

  return (
    <BaseURLProvider override={baseURLOverride}>
      <ToastProvider>
        <TimeZoneProvider currentTimeZoneIds={timeZones}>
          <NextIntlClientProvider
            now={now}
            locale={locale}
            messages={localeMessages}
            timeZone={timeZones.effective}
          >
            {children}
          </NextIntlClientProvider>
        </TimeZoneProvider>
      </ToastProvider>
    </BaseURLProvider>
  )
}

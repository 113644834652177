"use client"

import React from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { useNow } from "../../lib/useNow"

export const Favicon = () => {
  const now = useNow()

  const html = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      style={{
        fontWeight: "semibold",
      }}
      fill="transparent"
    >
      <rect width={16} height={16} rx={3} fill="#C567C1" />
      <rect
        width={14}
        height={14}
        rx={2}
        stroke="rgba(0,0,0,0.2)"
        strokeWidth={1}
        x={1}
        y={1}
      />
      <text
        fill="white"
        x="50%"
        y="9"
        dominantBaseline="middle"
        textAnchor="middle"
        fontFamily="Inter, sans-serif"
        fontSize="10"
      >
        {now.day}
      </text>
    </svg>
  )

  const favicon = encodeURIComponent(renderToStaticMarkup(html))

  return (
    <link
      key="favicon"
      rel="shortcut icon"
      href={`data:image/svg+xml,${favicon}`}
      type="image/svg+xml"
    />
  )
}

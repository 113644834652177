"use client"

import React, { createContext, useCallback, useContext } from "react"
import { setCookie, deleteCookie } from "../../../../lib/cookie"
import { BASE_URL_OVERRIDE_COOKIE_NAME } from "../../../../config/base-url"

interface BaseURLSetterProps {
  children: React.ReactNode
  override?: string
}

type BaseURLContext = {
  setBaseURLOverride: (override: string, reload?: boolean) => void
  clearBaseURLOverride: (reload?: boolean) => void
} & { baseURL?: string; overrideApplied: boolean }
const baseURLContext = createContext<BaseURLContext>({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  overrideApplied: false,
  setBaseURLOverride: () => {
    throw new Error("setBaseURLOverride can only be used in a BaseURLProvider")
  },
  clearBaseURLOverride: () => {
    throw new Error(
      "clearBaseURLOverride can only be used in a BaseURLProvider",
    )
  },
})
export const BaseURLContextProvider = baseURLContext.Provider
export const useBaseURL = () => useContext(baseURLContext)

export const BaseURLProvider = React.memo((props: BaseURLSetterProps) => {
  const { children, override } = props

  const setBaseURLOverride = useCallback((override: string, reload = true) => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      return
    }
    setCookie(BASE_URL_OVERRIDE_COOKIE_NAME, override, {
      path: "/",
      sameSite: "lax",
    })

    if (reload) {
      window.location.reload()
    }
  }, [])

  const clearBaseURLOverride = useCallback((reload = true) => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      return
    }
    deleteCookie(BASE_URL_OVERRIDE_COOKIE_NAME, {
      path: "/",
      sameSite: "lax",
    })
    if (reload) {
      window.location.reload()
    }
  }, [])

  const baseURL =
    process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? process.env.NEXT_PUBLIC_API_BASE_URL
      : override || process.env.NEXT_PUBLIC_API_BASE_URL

  return (
    <BaseURLContextProvider
      value={{
        baseURL,
        overrideApplied: baseURL !== process.env.NEXT_PUBLIC_API_BASE_URL,
        setBaseURLOverride,
        clearBaseURLOverride,
      }}
    >
      {children}
    </BaseURLContextProvider>
  )
})
BaseURLProvider.displayName = "TimeZoneProvider"

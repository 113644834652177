import { serialize, CookieSerializeOptions } from "cookie"

export const decodeCookie = (str?: string) => {
  if (!str) return str
  return str.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent)
}

export type TmpCookiesObj =
  | { [key: string]: string }
  | Partial<{ [key: string]: string }>

const getCookies = () => {
  const _cookies: TmpCookiesObj = {}
  const documentCookies = document.cookie ? document.cookie.split("; ") : []
  for (let i = 0, len = documentCookies.length; i < len; i++) {
    const cookieParts = documentCookies[i].split("=")
    const _cookie = cookieParts.slice(1).join("=")
    const name = cookieParts[0]
    _cookies[name] = _cookie
  }
  return _cookies
}

export const getCookie = (name: string) => {
  const cookies = getCookies()
  return decodeCookie(cookies[name])
}

export const setCookie = (
  key: string,
  data: string,
  options?: CookieSerializeOptions,
) => {
  document.cookie = serialize(key, data, {
    path: "/",
    ...options,
  })
}

export const deleteCookie = (
  key: string,
  options?: CookieSerializeOptions,
): void => {
  return setCookie(key, "", { ...options, maxAge: -1 })
}

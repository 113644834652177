import { useEffect, useRef } from "react"

/*
 * `useInterval` is a declarative wrapper around `setInterval` allowing us to
 * run some task at a particular interval.
 * Full explanation: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback A callback function to be invoked at the provided interval
 * @param delay A delay in ms between invocations.
 */
const useInterval = (
  callback: () => void | Promise<void>,
  delay?: number,
): void => {
  const savedCallback = useRef<() => void | Promise<void>>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && void savedCallback.current()
    }
    if (delay !== undefined) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return
  }, [delay])
}

export default useInterval

import { DateTime } from "luxon"
import { useLocale, useNow as useNowNextIntl, useTimeZone } from "next-intl"
import { useMemo } from "react"

/**
 * This is a client hook wrapping the date/time functionality of next-intl.
 * It takes information from this library's context and produces a DateTime object.
 * It's important to make sure that the time used to render the page on the client
 * initially is the same time used on the server, to prevent rehydration issues.
 * This hook will take care of that.
 * @param updateIntervalMs If this is provided the hook will update the time every X milliseconds, default is 60 seconds.
 * @returns A DateTime object representing the current time.
 */
export const useNow = (updateIntervalMs = 60000) => {
  const tz = useTimeZone()

  const locale = useLocale()
  const now = useNowNextIntl({
    updateInterval: updateIntervalMs,
  })
  return useMemo(() => {
    return DateTime.fromJSDate(now, { zone: tz }).setLocale(locale)
  }, [tz, locale, now])
}

"use client"

import React, { createContext, useCallback, useContext, useState } from "react"
import { useToast } from "@daybridge/components"
import {
  TIME_ZONE_COOKIE_NAME,
  TIME_ZONE_OVERRIDE_COOKIE_NAME,
} from "../../../../config/timezone"
import { getCookie, setCookie, deleteCookie } from "../../../../lib/cookie"
import {
  AppTimeZoneIds,
  LAST_RESORT_TIMEZONE,
} from "../../../../lib/timezones/timezone"
import useInterval from "../../../../lib/useInterval"

interface TimeZoneSetterProps {
  // `currentTimeZone` is the time zone used to render the app
  currentTimeZoneIds: AppTimeZoneIds
  children: React.ReactNode
}

export type TimeZoneContext = {
  setTimeZoneOverride: (zoneId: string, reload: boolean) => void
  clearTimeZoneOverride: (reload: boolean) => void
} & AppTimeZoneIds
const timeZoneContext = createContext<TimeZoneContext>({
  system: LAST_RESORT_TIMEZONE.id,
  effective: LAST_RESORT_TIMEZONE.id,
  setTimeZoneOverride: () => {
    throw new Error(
      "setTimeZoneOverride can only be used in a TimeZoneProvider",
    )
  },
  clearTimeZoneOverride: () => {
    throw new Error(
      "clearTimeZoneOverride can only be used in a TimeZoneProvider",
    )
  },
})
export const TimeZoneContextProvider = timeZoneContext.Provider
export const useTimeZone = () => useContext(timeZoneContext)

export const TimeZoneProvider = React.memo((props: TimeZoneSetterProps) => {
  const { children, currentTimeZoneIds: currentTimeZone } = props

  /**
   * --------------------
   * Timezone Warning Toast
   * --------------------
   * This code shows a warning toast if the user's device timezone changes.
   * It also provides a button to reload the page with the new timezone.
   */
  //const t = useTranslations("time_zones") TODO TRANSLATION
  const toast = useToast()
  const [warningToastId, setWarningToastId] = useState<string | null>(null)
  const sendWarningToast = useCallback(() => {
    if (warningToastId) return
    setWarningToastId(
      toast.warning({
        icon: "ClockChange",
        title: "Time zone changed",
        message: "switch_time_zone_description",
        action: {
          label: "switch_time_zone",
          onClick: () => window.location.reload(),
        },
        duration: Infinity,
        preventManualDismissal: true,
      }).id,
    )
  }, [warningToastId, toast])

  const notifyUserIfTimezoneOrOverrideChanges = useCallback(() => {
    const currentCookieZone = getCookie(TIME_ZONE_COOKIE_NAME)
    const currentCookieOverride = getCookie(TIME_ZONE_OVERRIDE_COOKIE_NAME)

    // This might happen if the user clears their cookies, but should be corrected
    // by the next interval.
    if (!currentCookieZone) return

    const actualEffectiveZone = currentCookieOverride || currentCookieZone
    if (actualEffectiveZone !== currentTimeZone.effective && !warningToastId) {
      sendWarningToast()
    }
  }, [sendWarningToast, warningToastId, currentTimeZone])

  /**
   * --------------------
   * Timezone Cookie Sync
   * --------------------
   * This code keeps the timezone cookie in sync with the user's device.
   * If the user changes their device timezone, we want to update the cookie.
   * We check every 5 seconds.
   */
  const updateTimezoneCookieWhenDeviceTimezoneChanges = useCallback(() => {
    const currentCookieZone = getCookie(TIME_ZONE_COOKIE_NAME)
    const newZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (currentCookieZone !== newZone) {
      setCookie(TIME_ZONE_COOKIE_NAME, newZone, {
        path: "/",
        sameSite: "lax",
      })
      notifyUserIfTimezoneOrOverrideChanges()
    }
  }, [notifyUserIfTimezoneOrOverrideChanges])
  useInterval(updateTimezoneCookieWhenDeviceTimezoneChanges, 1000 * 5)

  const setTimeZoneOverride = useCallback(
    (override: string, reload = false) => {
      if (override === Intl.DateTimeFormat().resolvedOptions().timeZone) {
        deleteCookie(TIME_ZONE_OVERRIDE_COOKIE_NAME, {
          path: "/",
          sameSite: "lax",
        })
      } else {
        setCookie(TIME_ZONE_OVERRIDE_COOKIE_NAME, override, {
          path: "/",
          sameSite: "lax",
        })
      }
      if (reload) {
        window.location.reload()
      } else {
        notifyUserIfTimezoneOrOverrideChanges()
      }
    },
    [notifyUserIfTimezoneOrOverrideChanges],
  )

  const clearTimeZoneOverride = useCallback(
    (reload = false) => {
      deleteCookie(TIME_ZONE_OVERRIDE_COOKIE_NAME, {
        path: "/",
        sameSite: "lax",
      })
      if (reload) {
        window.location.reload()
      } else {
        notifyUserIfTimezoneOrOverrideChanges()
      }
    },
    [notifyUserIfTimezoneOrOverrideChanges],
  )

  return (
    <TimeZoneContextProvider
      value={{
        effective: currentTimeZone.effective,
        system: currentTimeZone.system,
        override: currentTimeZone.override,
        setTimeZoneOverride,
        clearTimeZoneOverride,
      }}
    >
      {children}
    </TimeZoneContextProvider>
  )
})
TimeZoneProvider.displayName = "TimeZoneProvider"
